<template>
  <div class="head_top">
    <img src="../../../../public/images/二级海报/公司荣誉.jpg" lazy width="100%" height="100%">

    <div class="box">
      <div class="title">公司荣誉</div>
      <div class="fonts">高新技术企业证书</div>

      <div class="flex">
        <div><img src="../../../../public/images/底部导航/关于我们/公司荣誉/高新技术企业证书/证书1.jpg"></div>
        <div><img src="../../../../public/images/底部导航/关于我们/公司荣誉/高新技术企业证书/证书2.jpg" lazy width="440px" height="311px"></div>
      </div>

      <div class="fonts">实用型专利证书</div>
      <div class="flex">
        <div><img src="../../../../public/images/底部导航/关于我们/公司荣誉/实用型专利证书/证书1.jpg" lazy width="140px" height="200px"></div>
        <div><img src="../../../../public/images/底部导航/关于我们/公司荣誉/实用型专利证书/证书2.jpg" lazy width="140px" height="200px"></div>
        <div><img src="../../../../public/images/底部导航/关于我们/公司荣誉/实用型专利证书/证书2.jpg" lazy width="140px" height="200px"></div>
        <div><img src="../../../../public/images/底部导航/关于我们/公司荣誉/实用型专利证书/证书2.jpg" lazy width="140px" height="200px"></div>
      </div>

      <div class="fonts">著作权登记证书</div>
      <div class="flex">
        <div><img src="../../../../public/images/底部导航/关于我们/公司荣誉/著作权登记证书/摄像机界面证书1.jpg" lazy width="140px" height="200px"></div>
        <div><img src="../../../../public/images/底部导航/关于我们/公司荣誉/著作权登记证书/证书2.jpg" lazy width="140px" height="200px"></div>
        <div><img src="../../../../public/images/底部导航/关于我们/公司荣誉/著作权登记证书/证书3.jpg" lazy width="140px" height="200px"></div>
        <div><img src="../../../../public/images/底部导航/关于我们/公司荣誉/著作权登记证书/证书4.jpg" lazy width="140px" height="200px"></div>
      </div>
      <div class="flex">
        <div><img src="../../../../public/images/底部导航/关于我们/公司荣誉/著作权登记证书/证书5.jpg" lazy width="140px" height="200px"></div>
        <div><img src="../../../../public/images/底部导航/关于我们/公司荣誉/著作权登记证书/证书6.jpg" lazy width="140px" height="200px"></div>
        <div><img src="../../../../public/images/底部导航/关于我们/公司荣誉/著作权登记证书/证书7.jpg" lazy width="140px" height="200px"></div>
        <div><img src="../../../../public/images/底部导航/关于我们/公司荣誉/著作权登记证书/证书8.jpg" lazy width="140px" height="200px"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "公司荣誉"
}
</script>

<style scoped>
.box{
  width: 80%;
  margin: auto;
}
.title{
  text-align: center;
  font-size:24px;
  margin-top: 50px;
}
.fonts{
  text-align: center;
  margin-top: 50px;
}
.flex{
  display: flex;
  justify-content: space-evenly;
  padding-top: 30px;
}
</style>